import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />

    <main className="page_404">
      <div className="container-fluid">
        <div className="page_header_wrapper">
          <h1 className="page_header" data-text="&#60;404/&#62;">
            &#60;404/&#62;
          </h1>
        </div>
        <p>Ooops, coś poszło nie tak...</p>
        <Link to="/" className="btn_custom">
          &#60;Strona główna/&#62;
        </Link>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
